export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/2AgoDemo": [3],
		"/activationPlot": [4],
		"/ameoActivationTest": [5],
		"/balancedParenthesis": [6],
		"/binaryActivation": [7],
		"/classificationDemo": [8],
		"/functionComplexity": [9],
		"/gatedFSMDemo": [10],
		"/grokkingLossDemo": [~11],
		"/loadWeights": [~12],
		"/logicGateSwitcherDemo": [13],
		"/nodeViz": [14],
		"/pruningDemo": [15],
		"/replace1To111Demo": [16],
		"/rnn": [17],
		"/sparseRegularizerPlot": [18],
		"/ternary": [19],
		"/threeStateLoopDemo": [20],
		"/train": [21],
		"/voxelViz": [22]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';